<template>
  <div class="ladies project-component">
    <div class="fullsection">
      <p>
        This project was a reoccurring freelancing design job, that allowed me to conceive a first and a second version
        of a mobile app that would be used during 2 yearly editions of the Tech for good event at our school.
      </p>
      <br />
      <p>
        There were two target audiences : the app would be used by the conference masters, company representatives
        attending and by students. Very different age groups with different tech abilities, and of course, especially
        since it was the main focus of the event, the app had to be easy to use and accessible.
      </p>
      <br />
    </div>

    <div class="half-section half-section-media screenshots">
      <img src="@/assets/projects/efrei/album/2.png" alt="" />
    </div>

    <div class="full-section">
      <p>
        The main challenge for this app was to collect, translate and challenge the clients requirements, and adapting
        the design to a diverse crowd.
      </p>
    </div>
    <div class="half-section half-section-media">
      <img src="@/assets/projects/efrei/album/1.png" alt="" />
    </div>
    <p></p>
    <p>
      Feel free to contact <a href="https://fr.linkedin.com/in/seynimbaye" class="links animated-links" target="_blank">Seyni M’Baye</a>, director of
      company relations at Efrei Paris, who can recommend me for my involvement in this exciting project.
    </p>

    <div class="lesson-box">
      <h2>What did I learn ?</h2>
      <br />
      <p>
        I learned about accessibility best practices in UX/UI design, to make an app useable by the most diverse crowd
        possible.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Euris",
};
</script>
<style scoped>

</style>
